const birthdayDateStr = "May 23, 2024 12:00:00 EST";
const birthdayDateObj = new Date(birthdayDateStr);

const updateTimer = () => {
  const now = new Date();
  
  const diff = birthdayDateObj - now;

  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor(diff / (1000 * 60 * 60));
  const mins = Math.floor(diff / (1000 * 60));
  const secs = Math.floor(diff / 1000);

  let d = days;
  let h = hours - days * 24;
  let m = mins - hours * 60;
  let s = secs - mins * 60;

  if(d < 0 || h < 0 || m < 0 || s < 0) {
    d = 0;
    h = 0;
    m = 0;
    s = 0;
  }

  document.querySelector(".days .big").innerHTML = ("0" + d).slice(-2);
  document.querySelector(".hours .big").innerHTML = ("0" + h).slice(-2);
  document.querySelector(".minutes .big").innerHTML = ("0" + m).slice(-2);
  document.querySelector(".seconds .big").innerHTML = ("0" + s).slice(-2);
};

window.addEventListener("DOMContentLoaded", (event) => {
  updateTimer()
  setInterval(updateTimer, 1000);
});
